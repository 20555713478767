import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Introduction`}</h2>
    <p>{`We use cookies to ensure this site operates optimally and delivers the best possible experience to you. This Cookie Policy explains how we use cookies on our website and services.`}</p>
    <h2>{`What Are Cookies?`}</h2>
    <p>{`Cookies are small text files placed on your device to store data that can be retrieved by a web server in the domain that placed the cookie. They allow the website to recognize your device and maintain functionality during your visit.`}</p>
    <h2>{`Types of Cookies We Use`}</h2>
    <ol>
      <li parentName="ol">{`Strictly Necessary Cookies`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`These cookies are crucial to ensure the website functions properly. They support core functionalities such as page navigation and access to secure areas of the site.`}</li>
      <li parentName="ul">{`Purpose: To maintain site functionality.`}</li>
      <li parentName="ul">{`Data Collected: No personal data is collected or stored by these cookies.`}</li>
    </ul>
    <h2>{`Your Consent`}</h2>
    <p>{`By using our website and services, you agree to our use of cookies as outlined in this Cookie Policy. These cookies are necessary to provide you with the services you are accessing on our site.`}</p>
    <h2>{`No Personal Data Collection`}</h2>
    <p>{`We do not collect any personal data through the use of cookies. Cookies are used solely to maintain site functionality and nehance your browsing experience.`}</p>
    <h2>{`Managing Cookies`}</h2>
    <p>{`You can manage or delete cookies through your web browser settings. However, please note that disabling essential cookies may affect the functionality of our website. For more information about cookies and how to manage them, visit `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org"
      }}>{`www.allaboutcookies.org`}</a>{`.`}</p>
    <h2>{`Updates to This Policy`}</h2>
    <p>{`We may update this Cookie Policy from time to time to reflect changes in our use of cookies or for other operational, legal, or regulatory reasons. When we make material changes, we will notify you clearly, such as by updating this page.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      